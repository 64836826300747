import { Action } from '@ngrx/store';
import { type } from './util';
import { ISupportContacts } from './core/services/supportContacts/interfaces';
import { ToastrTypesEnum, LoadingStatusEnum, ServiceStatusEnum } from './constants';
import { IManagerProfileViewModel } from './core/services/managers/interfaces';

export const ActionTypes = {
  SET_MANAGER: type('[App] set manager data'),
  GET_MANAGER: type('[App] get manager data'),
  CLEAR_MANAGER: type('[App] clear manager data'),
  REQUEST_START: type('[App] request start'),
  REQUEST_END: type('[App] request end'),
  SPINNER_SHOW: type('[App] show spinner'),
  SPINNER_HIDE: type('[App] hide spinner'),
  TOGGLE_NEED_HELP: type('[App Header] toggle need help'),
  FETCH_SUPPORT_CONTACTS: type('[App] fetch support contacts'),
  SET_SUPPORT_CONTACTS: type('[App] set support contacts'),
  GO_TO_OKTA_LOGIN_PAGE: type('[App] go to okta login page'),
  OKTA_LOGOUT: type('[App] okta logout'),
  OKTA_FORCE_LOGOUT: type('[App] okta force logout'),
  RECRUIT_AUTH: type('[App] recruit authentication change'),
  SEND_AUTH_CODE: type('[App] send Okta auth code'),
  TOGGLE_SIDE_BAR: type('[App] toggle mobile sidebar'),
  SET_SCROLL_POSITION: type('[App] set scroll position'),
  SHOW_TOASTR: type('[App] show toastr'),
  SHOW_ACTION_TOASTR: type('[App] show action toastr'),
  HIDE_TOASTR: type('[App] hide toastr'),
  SET_REFRESH_TOKEN_TIMER: type('[App] set refresh token timer'),
  SET_LOADING_STATE: type('[App] set loading state'),
  SET_INSIDER_TIP_VISIBILITY_STATUS: type('[App] set insider tip visibility status'),
  SHOW_INSIDER_TIP: type('[App] show insider tip'),
  HIDE_INSIDER_TIP: type('[App] hide insider tip'),
  SET_SERVICE_STATUS: type('[App] set service status'),
  SET_IMPERSONATION: type('[App] set impersonation status'),
};

export class ApplicationManager implements Action {
  public type = ActionTypes.SET_MANAGER;

  constructor(public payload: IManagerProfileViewModel) {}
}

export class GetApplicationManager implements Action {
  public type = ActionTypes.GET_MANAGER;

  constructor(public payload: string) {}
}

export class ClearApplicationManager implements Action {
  public type = ActionTypes.CLEAR_MANAGER;

  constructor(public payload?: any) {}
}

export class RequestStart implements Action {
  public type = ActionTypes.REQUEST_START;

  constructor(public payload?: any) {}
}

export class RequestEnd implements Action {
  public type = ActionTypes.REQUEST_END;

  constructor(public payload?: any) {}
}

export class ShowSpinner implements Action {
  public type = ActionTypes.SPINNER_SHOW;

  constructor(public payload?: any) {}
}

export class HideSpinner implements Action {
  public type = ActionTypes.SPINNER_HIDE;

  constructor(public payload?: any) {}
}

export class ToggleNeedHelp implements Action {
  public type = ActionTypes.TOGGLE_NEED_HELP;

  constructor(public payload?: any) {}
}

export class FetchSupportContacts implements Action {
  public type = ActionTypes.FETCH_SUPPORT_CONTACTS;

  constructor(public payload?: any) {}
}

export class SetSupportContacts implements Action {
  public type = ActionTypes.SET_SUPPORT_CONTACTS;

  constructor(public payload: ISupportContacts) {}
}

export class GoToOktaLoginPage implements Action {
  public type = ActionTypes.GO_TO_OKTA_LOGIN_PAGE;

  constructor(public payload?: any) {}
}

export class LogOut implements Action {
  public type = ActionTypes.OKTA_LOGOUT;

  constructor(public payload?: any) {}
}

export class ForceLogOut implements Action {
  public type = ActionTypes.OKTA_FORCE_LOGOUT;

  constructor(public payload?: any) {}
}

export class RecruitAuthentication implements Action {
  public type = ActionTypes.RECRUIT_AUTH;

  constructor(public payload: boolean) {}
}

export class SendAuthCode implements Action {
  public type = ActionTypes.SEND_AUTH_CODE;

  constructor(public payload: {
    code: string;
    redirectUri: string;
  }) {}
}

export class ToggleSideBar implements Action {
  public type = ActionTypes.TOGGLE_SIDE_BAR;

  constructor(public payload: boolean) {}
}

export class SetWindowScrollPosition implements Action {
  public type = ActionTypes.SET_SCROLL_POSITION;

  constructor(public payload: number) {}
}

export class ShowToastr implements Action {
  public type = ActionTypes.SHOW_TOASTR;

  constructor(public payload: {
    type: ToastrTypesEnum,
    message: string,
  }) {}
}

export class ShowActionToastr implements Action {
  public type = ActionTypes.SHOW_ACTION_TOASTR;

  constructor(public payload: {
    type: ToastrTypesEnum,
    message: string,
    btnTitle?: string,
    action: any
  }) {}
}

export class HideToastr implements Action {
  public type = ActionTypes.HIDE_TOASTR;

  constructor(public payload?: any) {}
}

export class SetRefreshTokenTimer implements Action {
  public type = ActionTypes.SET_REFRESH_TOKEN_TIMER;

  constructor(public payload?: any) {}
}

export class SetLoadingState implements Action {
  public type = ActionTypes.SET_LOADING_STATE;

  constructor(public payload: {
    status: LoadingStatusEnum;
    message?: string;
  }) {}
}

export class SetInsiderTipVisibilityStatus implements Action {
  public type = ActionTypes.SET_INSIDER_TIP_VISIBILITY_STATUS;

  constructor(public payload: boolean) {}
}

export class ShowInsiderTip implements Action {
  public type = ActionTypes.SHOW_INSIDER_TIP;

  constructor(public payload?: any) {}
}

export class HideInsiderTip implements Action {
  public type = ActionTypes.HIDE_INSIDER_TIP;

  constructor(public payload?: any) {}
}

export class SetServiceStatus implements Action {
  public type = ActionTypes.SET_SERVICE_STATUS;

  constructor(public payload: ServiceStatusEnum) {}
}

export class SetImpersonationStatus implements Action {
  public type = ActionTypes.SET_IMPERSONATION;

  constructor(public payload: boolean) {}
}

export type TypeAction =
  ApplicationManager |
  RequestStart |
  RequestEnd |
  ShowSpinner |
  HideSpinner |
  ToggleNeedHelp |
  SetSupportContacts |
  GoToOktaLoginPage |
  RecruitAuthentication |
  ClearApplicationManager |
  SendAuthCode |
  ToggleSideBar |
  ShowToastr |
  HideToastr |
  SetRefreshTokenTimer |
  SetLoadingState |
  ShowInsiderTip |
  HideInsiderTip |
  SetServiceStatus |
  SetImpersonationStatus |
  SetWindowScrollPosition |
  SetInsiderTipVisibilityStatus |
  FetchSupportContacts;
